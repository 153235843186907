import { Route } from "react-router";
import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Stack, Text, Image, ImageFit, Link, Icon } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/style-utilities";
import { Trans, useTranslation } from 'react-i18next';
import { ExternalLink, SelectionList } from "../../components";
import { useAppActions, useAppState } from "../../states";
import { recordSelfHelpActivated } from '@src/data';
import keySvg from '@src/resources/key_image.svg';

import barricadeSvg from '@src/resources/barricade.svg'

interface InstallationIDButtonProps {
    icon?: string,
    text: string,
    iconHeight?: number,
    onClick: () => void
}

const InstallationIDButton = (props: InstallationIDButtonProps) => {
    const buttonStyles = {
        root: {
            isButton: true,
            height: "43px",
            radius: "8px",
            color: "#267ac2",
            borderColor: "#267ac2",
            border: "1px solid",
            borderRadius: "8px",
            backgroundColor: "white",
            marginLeft: 25,
            marginRight: 25,
            textAlign: "center"
        }
    }
    const buttonTextStyles = {
        root: {
            font: "SF Pro Text",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "20px",
            letter: "-0.24px",
            textAlign: "center",
            height: "43px",
            radius: "8px",
            color: "#267ac2",
        }
    }
    let content = (
        <Text styles={buttonTextStyles}>
            {props.text}
        </Text>
    );
    if (props.icon !== undefined) {
        const iconStyles = {
            root: {
                height: props.iconHeight,
                width: "100%"
            },
            image: {
                height: "100%"
            }
        };
        content = (
            <Image src={props.icon} imageFit={ImageFit.center} alt={props.text} styles={iconStyles} />
        );
    }

    return (
        <Link onClick={props.onClick} styles={buttonStyles}>
            {content}
        </Link>
    );
}

const productItemClassNames = mergeStyleSets({
    itemCell: {
        width: "345px",
        height: "48px",
        paddingLeft: "16px",
        paddingRight: "16px",
        borderBottom: "1px solid #E1E1E1",
        display: "flex",
        alignItems: "center",
        background: "#FFFFFF",
        selectors: {
            "&:hover": { background: "#E1E1E1" },
        },
    },
    text: {
        fontWeight: "400px",
        fontSize: "17px",
        color: "#0078D4",
        flexGrow: 1
    },
    chevronStyles: {
        alignSelf: "center",
        marginLeft: "10px",
        color: "#212121",
        fontSize: "12px",
        fontWeight: 800,
        flexShrink: 0,
    },
});

const selfHelpProductGroup = "Windows";
const selfHelpProductMinLaunchOrder = 6;  // Windows 10

export const HelpLandingPage = () => {
    const { t } = useTranslation();
    const { selectedProduct } = useAppState();
    const { navigateTo } = useAppActions();
    const gotoSawIIDPage = useCallback(() => {
        navigateTo(`/activate`);
    },[navigateTo]);
    const gotoNoIIDPage = useCallback(() => {
        if (selectedProduct === undefined
            || selectedProduct.productGroup !== selfHelpProductGroup
            || selectedProduct.productLaunchOrder < selfHelpProductMinLaunchOrder) {
            navigateTo("/selfhelp/unsupported");
        } else {
            navigateTo("/selfhelp/windows");
        }
    }, [navigateTo, selectedProduct]);

    const headerStyles = {
        root: {
            height: "28px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "28px",
            align: "center",
            textAlign: "center",
            color: "#212121",
            marginBottom: 0
        }
    };
    const textStyles = {
        root: {
            top: "225px",
            font: "SF Pro Text",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "20px",
            letter: "-0.24px",
            color: "#000000",
            paddingLeft: 35,
            paddingRight: 35,
        }
    };
    const linkStyles = {
        root: {
            font: "SF Pro Text",
            weight: "500",
            size: "15px",
            lineHeight: "20px",
            letter: "-0.24px",
            fontColor: "#2E7CF6",
            textDecoration: "none!important",
            paddingTop: "14px"
        }
    };

    return (
        <Stack horizontalAlign="stretch">
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.landing.title", "Microsoft Product Activation Help")}
            </Text>
            <Text as="p" styles={textStyles}>
                <Trans i18nKey="helpPage.landing.body">
                    <br />To locate your product’s Installation ID, locate your product and follow the instructions:<br />
                    <br />
                    <strong>Windows and Windows Server Only</strong><br />
                    You can launch the Activation Window by running a command. On the <strong>Start</strong> menu, select and run <strong>command prompt</strong>, then type <strong>SLUI 4</strong>. Select your <strong>Country</strong> and then <strong>Next</strong>.<br />
                    <br />
                    <strong>All Products</strong><br />
                    Alternatively, you can navigate to the <strong>activate by telephone</strong> instructions within the Activation Window by following the steps in the article applicable to your product:<br />
                </Trans>
            </Text>
            <Text as="ul" styles={linkStyles}>
                <Trans i18nKey="helpPage.landing.bodyList">
                    <li><a style={{ font: "SF Pro Text", color: "#2669d1", fontWeight: "500", fontSize: "15px", lineHeight: "20px", letterSpacing: "-0.24px" }} href="https://support.microsoft.com/en-us/windows/product-activation-for-windows-online-support-telephone-numbers-35f6a805-1259-88b4-f5e9-b52cccef91a0" target="_blank" rel="noreferrer noopener">Windows</a></li>
                    <li><a style={{ font: "SF Pro Text", color: "#2669d1", fontWeight: "500", fontSize: "15px", lineHeight: "20px", letterSpacing: "-0.24px" }} href="https://support.microsoft.com/en-us/office/activate-office-using-the-activation-wizard-1144e0de-e849-496e-8e33-ed6fb1b34202" target="_blank" rel="noreferrer noopener">Office</a></li>
                    <li><a style={{ font: "SF Pro Text", color: "#2669d1", fontWeight: "500", fontSize: "15px", lineHeight: "20px", letterSpacing: "-0.24px" }} href="https://support.microsoft.com/en-us/office/activate-office-for-mac-7f6646b1-bb14-422a-9ad4-a53410fcefb2" target="_blank" rel="noreferrer noopener">Office for Mac</a></li>
                </Trans>
            </Text>
            <InstallationIDButton
                text={t("helpPage.landing.seeIID", "I see my Installation ID")}
                onClick={gotoSawIIDPage}
            />
            <br />
            <InstallationIDButton
                text={t("helpPage.landing.noIID", "I don’t have/cannot see an Installation ID")}
                onClick={gotoNoIIDPage}
            />
        </Stack>
    );
}

export const UnsupportedProductView = () => {
    const { t } = useTranslation();

    const containerStyles = {
        root: {
            paddingLeft: 16,
            paddingRight: 16,
            alignItems: "center",
            position: 'absolute' as 'absolute',
            minHeight: "100%"
        }
    };
    const headerStyles = {
        root: {
            fontSize: 22,
            fontWeight: "600",
            textAlign: "center",
            marginTop: 34,
            marginBottom: 45,
            display: "block"
        }
    };
    const imageStyles = {
        root: {
            height: 156,
            width: "100%",
            marginBottom: 47
        },
        image: {
            height: 156,
        }
    };
    const bodyStyles = {
        root: {
            fontSize: 16,
            fontWeight: "400",
            display: "inline-block"
        }
    };
    const closePromptStyles = {
        root: {
            fontSize: 14,
            fontWeight: "400",
            marginBottom: 25,
        }
    };

    return (
        <Stack styles={containerStyles}>
            <Text styles={headerStyles}>
                {t("errorPage.title", "Microsoft Product Activation")}
            </Text>
            <Image src={barricadeSvg} imageFit={ImageFit.center} alt="Error." styles={imageStyles} />
            <Stack.Item grow={1}>
                <Text styles={bodyStyles}>
                    {t("helpPage.unsupportedBody1", "To activate your product you’ll need your Installation ID. When you’re at your computer with the Activation Window open, you’ll find the Installation ID below the phone number you initially dialed. Please contact us back when you have the installation ID and are ready to activate your product.")}
                    <br /><br />
                    {t("helpPage.unsupportedBody2", "Thank you for contacting Microsoft, goodbye!")}
                </Text>
            </Stack.Item>
            <Text styles={closePromptStyles}>
                {t("errorPage.closeWindow", "You may close this window now")}
            </Text>
        </Stack>
    );
}

export const WindowsHelp = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoSuccessPage = useCallback(() => navigateTo("/activate/result/success"), [navigateTo]);
    const gotoWindows10 = useCallback(() => navigateTo("/selfhelp/windows10"), [navigateTo]);
    const WindowsOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoSuccessPage },
        { id: "no", name: t("common.no", "No"), action: gotoWindows10 }
    ];

    const headerStyles = {
        root: {
            height: "28px",
            top: "149px",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStyles = {
        root: {
            width: "344px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.windowsPage.title", "Help Activating Windows")}
            </Text>
            <Text as="p" styles={textStyles}>
                <Trans i18nKey="helpPage.windowsPage.body">
                    Run Troubleshooter:<br />
                    <br />
                    For Windows 10 and newer, the troubleshooter can resolve many issues.<br />
                    <br />
                    <strong>Windows 11</strong><br />Select the <strong>Start</strong> button, then select <strong>Settings</strong> &gt; <strong>System</strong> &gt; <strong>Activation</strong> &gt; <strong>Troubleshoot</strong><br />
                    <br />
                    <strong>Windows 10</strong><br />Select the <strong>Start</strong> button, then select <strong>Settings</strong> &gt; <strong>Windows Update</strong> &gt; <strong>Activation</strong> &gt; <strong>Troubleshoot</strong><br />
                    <br />
                    If the troubleshooter has completed AND you recently changed your hardware on that device, then click othe option "I changed hardware on this device recently".<br />
                    <br />
                    If you don't see the device you're using in the list of results, make sure that <strong>you signed in using the same Microsoft account you associated with the Windows</strong> digital license on your device.<br />
                    <br />
                    <strong>Did this resolve your issue?</strong>
                </Trans>
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={WindowsOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}
export const Windows10 = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoKeyPage = useCallback(() => navigateTo("/selfhelp/key"), [navigateTo]);
    const gotoOnlineStorePage = useCallback(() => navigateTo("/selfhelp/store/online"), [navigateTo]);
    const gotoAppStorePage = useCallback(() => navigateTo("/selfhelp/store/app"), [navigateTo]);
    const gotoOEMPage = useCallback(() => navigateTo("/selfhelp/oem"), [navigateTo]);
    const Windows10Options = [
        { id: "key", ariaLabel:"Do you have one of the following? I have a Windows 7 or newer key", name: t("helpPage.windows10Page.optionKey", "I have a Windows 7 or newer key"), action: gotoKeyPage },
        { id: "store", ariaLabel:"I purchased it from an online store", name: t("helpPage.windows10Page.optionStore", "I purchased it from an online store"), action: gotoOnlineStorePage },
        { id: "app", ariaLabel:"I purchased it from the Microsoft Store app", name: t("helpPage.windows10Page.optionApp", "I purchased it from the Microsoft Store app"), action: gotoAppStorePage },
        { id: "oem", ariaLabel:"I received a motherboard from an OEM", name: t("helpPage.windows10Page.optionOem", "I received a motherboard from an OEM"), action: gotoOEMPage }
    ];

    const headerStyles = {
        root: {
            height: "56px",
            top: "162px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
            
        }
    };
    const textStylesPrimary = {
        root: {
            height: "40px",
            top: "244px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "40px"
        }
    };
    const textStylesSecondary = {
        root: {
            height: "20px",
            top: "328px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.windows10Page.title", "Help Activating Windows 10 or Newer")}
            </Text>
            <Text as="p" styles={textStylesPrimary}>
                {t("helpPage.windows10Page.bodyOne", "You may be able to activate using a product key or with your digital license.")}
                <br />
                <br />
            </Text>
            <Text as="p" styles={textStylesSecondary}>
                {t("helpPage.windows10Page.bodyTwo", "Do you have one of the following:")}
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={Windows10Options} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}
export const WindowsKey = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoActivationPage = useCallback(() => navigateTo("/selfhelp/activation"), [navigateTo]);
    const gotoAssistancePage = useCallback(() => navigateTo("/contact"), [navigateTo]);
    const KeyOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoActivationPage },
        { id: "no", name: t("common.no", "No"), action: gotoAssistancePage }
    ];

    const headerStyles = {
        root: {
            height: "24px",
            top: "166px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesPrimary = {
        root: {
            width: "345px",
            top: "224px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "0"
        }
    };
    const textStylesSecondary = {
        root: {
            width: "347px",
            top: "494px",
            font: "Segoe UI",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10px"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.windowsKeyPage.title", "Windows 7 or newer key")}
            </Text>
            <Text as="p" styles={textStylesPrimary}>
                <Trans i18nKey="helpPage.windowsKeyPage.bodyOne">
                    A Windows product key is a 25-character code used to activate Windows. It looks like this:<br />
                    <br />
                    <strong style={{ fontWeight: "600", fontSize: "14px" }}>PRODUCT KEY: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX</strong><br />
                    <br />
                    If you bought a physical copy of Windows, the product key should be on a label or card inside the box that Windows came in.<br />
                    <br />
                    If Windows came preinstalled on your PC, the product key should appear on a sticker on your device.<br />
                    <br />
                </Trans>
            </Text>
            <Text as="p" styles={textStylesSecondary}>
                {t("helpPage.windowsKeyPage.bodyTwo", "Did you find your product key?")}
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={KeyOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}

export const MicrosoftStoreApp = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoSuccessPage = useCallback(() => navigateTo("/activate/result/success"), [navigateTo]);
    const gotoAssistancePage = useCallback(() => navigateTo("/contact"), [navigateTo]);
    const AppOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoSuccessPage },
        { id: "no", name: t("common.no", "No"), action: gotoAssistancePage }
    ];

    const headerStyles = {
        root: {
            height: "47px",
            top: "156px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesPrimary = {
        root: {
            width: "345px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesSecondary = {
        root: {
            width: "344px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#0072c9"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.storeAppPage.title", "Purchased in a Microsoft Store app")}
            </Text>
            <Text as="p" styles={textStylesPrimary}>
                <Trans i18nKey="helpPage.storeAppPage.body">
                    If you bought Windows from the Microsoft Store app, a confirmation email will be sent to the email address (Microsoft Account) that the digital license is
                    associated with.<br />
                    <br />
                    <strong style={{ fontWeight: "600" }}>You will need to use this same Microsoft Account to log into Windows to attempt re-activation.</strong><br />
                    <br />
                    To re-activate, see section "Activate using digital license" in <ExternalLink url="https://support.microsoft.com/en-us/windows/activate-windows-c39005d4-95ee-b91e-b399-2820fda32227" styles={textStylesSecondary}>Activate Windows</ExternalLink> article.<br />
                    <br />
                    <strong>Were you able to re-activate?</strong>
                </Trans>
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={AppOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}

export const MicrosoftStoreOnline = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoActivationPage = useCallback(() => navigateTo("/selfhelp/activation"), [navigateTo]);
    const gotoAssistancePage = useCallback(() => navigateTo("/contact"), [navigateTo]);
    const OnlineOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoActivationPage },
        { id: "no", name: t("common.no", "No"), action: gotoAssistancePage }
    ];

    const headerStyles = {
        root: {
            height: "47px",
            top: "157px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesPrimary = {
        root: {
            width: "345px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesSecondary = {
        root: {
            width: "347px",
            height: "20px",
            top: "542px",
            font: "Segoe UI",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.onlineStorePage.title", "Purchased from a Microsoft online store")}
            </Text>
            <Text as="p" styles={textStylesPrimary}>
                <Trans i18nKey="helpPage.onlineStorePage.body">
                    A Windows <strong style={{ fontWeight: "600" }}>product key is a 25-character code used to activate Windows</strong>. It looks like this:<br />
                    <br />
                    <strong style={{ fontWeight: "600", fontSize: "14px" }}>PRODUCT KEY: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX</strong><br />
                    <br />
                    <strong style={{ fontWeight: "600" }}>The product key is in the confirmation email you received after buying your digital copy of Windows.</strong><br />
                    <br />
                    If you purchased from the Microsoft online store, you can log on with the Microsoft Account that purchased Windows and find your product key in the order history.<br />
                    <br />
                    <strong style={{ fontWeight: "700" }}>Did you find your product key?</strong>
                </Trans>
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={OnlineOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}

export const OEM = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoActivationPage = useCallback(() => navigateTo("/selfhelp/activation"), [navigateTo]);
    const gotoAssistancePage = useCallback(() => navigateTo("/contact"), [navigateTo]);
    const OEMOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoActivationPage },
        { id: "no", name: t("common.no", "No"), action: gotoAssistancePage }
    ];

    const headerStyles = {
        root: {
            height: "24px",
            top: "166px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesPrimary = {
        root: {
            width: "345px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesSecondary = {
        root: {
            width: "315px",
            padding: "15px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            fontStyle: "italic",
            backgroundColor: "#FFFFFF",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesTertiary = {
        root: {
            width: "345px",
            font: "Segoe UI",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };

    return (
        <Stack>
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.oemPage.title", "Original equipment manufacturer (OEM)")}
            </Text>
            <Trans i18nKey="helpPage.oemPage.body">
                <Text as="p" styles={textStylesPrimary}>
                    On the device with the replaced motherboard, you need to retrieve the product key from the motherboard if available.<br />
                    <br />
                    To do this,
                </Text>
                <Text as="ol" styles={textStylesTertiary}>
                    <li>Open a Command Prompt, running it as administrator.</li>
                    <li>Run the following command:</li>
                </Text>
                <Text as="p" styles={textStylesSecondary}>
                    wmic path SoftwareLicensingService get OA3xOriginalProductKey
                </Text>
                <Text as="p" styles={textStylesPrimary}>
                    You should see a product key returned in the format
                </Text>
                <Text as="p" styles={textStylesSecondary} style={{ textAlign: "center" }}>
                    XXXXX-XXXXX-XXXXX-XXXXX-XXXXX
                </Text>
                <Text as="p" styles={textStylesPrimary}>
                    <strong>Were you able to retrieve your product key?</strong>
                </Text>
            </Trans>
            <div className={productItemClassNames.text}>
                <SelectionList items={OEMOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}

export const ProductActivation = () => {
    const { t } = useTranslation();
    const { urlId } = useAppState();
    const { navigateTo, setSelfHelpActivated } = useAppActions();
    const appInsights = useAppInsightsContext();
    const gotoSuccessPage = useCallback(() => {
        appInsights.trackEvent({ name: "SelfHelpActivationSuccess" });
        recordSelfHelpActivated(urlId);
        setSelfHelpActivated(true);
        navigateTo("/activate/result/success");
    }, [navigateTo, appInsights]);
    const gotoAssistancePage = useCallback(() => {
        appInsights.trackEvent({ name: "SelfHelpActivationFailed" });
        navigateTo("/contact");
    }, [navigateTo, appInsights]);
    const ActivationOptions = [
        { id: "yes", name: t("common.yes", "Yes"), action: gotoSuccessPage },
        { id: "no", name: t("common.no", "No"), action: gotoAssistancePage }
    ];

    const headerStyles = {
        root: {
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "226px",
            height: "28px",
            top: "170px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#000000",
            alignText: "center"
        }
    };
    const textStylesPrimary = {
        root: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "345px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000"
        }
    };
    const keyImageStyles = {
        root: {
            height: 93,
            marginBottom: -12,
            width: "100%"
        },
        image: {
            height: 53,
        }
    };

    return (
        <Stack horizontalAlign="stretch">
            <Text as="h1" styles={headerStyles}>
                {t("helpPage.activationPage.title", "Product Activation")}
            </Text>
            <Image src={keySvg} imageFit={ImageFit.centerContain} styles={keyImageStyles} alt="Key Icon." />
            <Text as="p" styles={textStylesPrimary}>
                <Trans i18nKey="helpPage.activationPage.body">
                    <br />
                    To activate Windows, enter the product key on your device. To do this:<br />
                    <br />
                    Select the Start button, and then select <strong>Settings</strong> &gt; <strong>Update & Security</strong> &gt; <strong>Activation</strong> &gt; <strong>locate Update product key section</strong> &gt; <strong>Change product key</strong>.<br />
                    <br />
                    <br />
                    <strong>Did your product successfully activate?</strong><br />
                    <br />
                </Trans>
            </Text>
            <div className={productItemClassNames.text}>
                <SelectionList items={ActivationOptions} onSelect={() => { }} />
            </div>
            <Outlet />
        </Stack>
    );
}

export const HelpPage = () => {
    return <Outlet />;
}

export const HelpPageRoutes = [
    <Route key="HelpPage" index element={<HelpLandingPage />} />,
    <Route key="Unsupported" path="unsupported" element={<UnsupportedProductView />} />,
    <Route key="WindowsHelp" path="windows" element={<WindowsHelp />} />,
    <Route key="Windows10" path="windows10" element={<Windows10 />} />,
    <Route key="WindowsKey" path="key" element={<WindowsKey />} />,
    <Route key="MicrosoftStoreApp" path="store/app" element={<MicrosoftStoreApp />} />,
    <Route key="MicrosoftStoreOnline" path="store/online" element={<MicrosoftStoreOnline />} />,
    <Route key="OEM" path="oem" element={<OEM />} />,
    <Route key="ProductActivation" path="activation" element={<ProductActivation />} />,
];